import SignaturePad from "signature_pad";

class signature {

	#options = {
		minWidth: 1,
		maxWidth: 2,
		backgroundColor: 'rgb(255, 255, 255)',
		throttle: 5,
	};
	#imageType = "image/jpeg";
	#canvas;
	#signaturePad;
	#canvasContainer;
	#input;
	#btns;

	constructor($canvas) {
		this.#canvas = $canvas;
		this.#signaturePad = new SignaturePad($canvas[0], this.#options);
		this.#canvasContainer = $canvas.closest('.signature-canvas-container');
		this.#input = this.#canvasContainer.find('input');
		this.#btns = this.#canvasContainer.find('a.button');
		this.setupEvents();
	};

	enableEdit(){
		this.#btns.filter('.btn-edit').addClass('button-blue').removeClass('button-grey').show();
		this.#btns.filter('.btn-save').removeClass('button-green').addClass('button-grey').hide();
		this.#btns.filter('.btn-clear').removeClass('button-red').addClass('button-grey').hide();
	}

	enableSaveClear(){
		this.#btns.filter('.btn-edit').removeClass('button-blue').addClass('button-grey').hide();
		this.#btns.filter('.btn-save').addClass('button-green').removeClass('button-grey').show();
		this.#btns.filter('.btn-clear').addClass('button-red').removeClass('button-grey').show();
	}

	disableSaveClear(){
		this.#btns.filter('.btn-edit').removeClass('button-blue').addClass('button-grey').hide();
		this.#btns.filter('.btn-save').removeClass('button-green').addClass('button-grey').show();
		this.#btns.filter('.btn-clear').removeClass('button-red').addClass('button-grey').show();
	}

	disableSignature(){
		// disable signature
		this.#signaturePad.off();

		// enable edit
		this.enableEdit();
	}

	setupEvents() {
		const that = this;

		// enable buttons
		this.#signaturePad.addEventListener('endStroke', function(){
			that.enableSaveClear();
			that.#btns.addClass('selected').removeClass('button-grey');
			that.#btns.filter('.btn-edit').removeClass('selected').hide();
		});

		this.#btns.on('click', function(){

			// do nothing
			if( $(this).hasClass('button-grey') ) {
				return;
			}

			let val = '';
			let action = 'save';

			if( $(this).hasClass('btn-clear') ) {

				action = 'clear';

				// CLEAR
				that.#signaturePad.clear();
				that.disableSaveClear();

			} else if( $(this).hasClass('btn-edit') ) {

				action = 'edit';

				// EDIT
				that.#signaturePad.on();
				that.enableSaveClear();

			} else {

				// update value
				val = that.#signaturePad.toDataURL(that.#imageType).split(';base64,')[1];

				// disable re-use of signature
				that.disableSignature();

				// remove any existing errors
				that.#input.removeClass('error').closest('p').removeClass('error').find('label.error').hide();
			}

			// save changes
			if( action !== 'edit' ){
				that.#input.val(val).trigger('change');
			}
		});

		// paint canvas
		if( this.#input.attr('value') ) {

			// paint canvas
			this.#signaturePad.fromDataURL(`data:${this.#imageType};base64,`+this.#input.val());

			this.disableSignature();
		}
	}
}

$(() => {

	if( app.OPTIONS.driver_signature ) {
		new signature(app.DOM.form.find('canvas.driver'));
		new signature(app.DOM.form.find('canvas.supervisor'));
	}

});